import moment from "moment";

import { ColDef, GridOptions } from "ag-grid-community";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";

import { Forecast } from "../../../interfaces";
import { currencyValueFormatter, currencyValueGetter, numberValueFormatter } from "../../../utils/helpers";

const BLUE = "#1f73b7";

const BudgetForecastResultGrid = ({ forecast }: { forecast: Forecast }) => {
  const baseUrl = `/budget/forecast/${forecast.id}`;

  let weeks: ColDef[] = [
    {
      field: "total_balanced_sales_quantity",
      headerName: "Qté totale",
      cellStyle: { textAlign: "right", color: BLUE },
      valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      columnGroupShow: "closed",
    },
  ];

  const weeksNumber = moment({ year: forecast.year }).isoWeeksInYear();
  Array(weeksNumber)
    .fill(0)
    .forEach((_, i) =>
      weeks.push({
        field: `s${String(i + 1).padStart(2, "0")}`,
        type: "numericColumn",
        columnGroupShow: "open",
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      })
    );

  const columnDefs = [
    {
      headerName: "Combinaisons",
      children: [
        { field: "product_family_label", headerName: "Famille", filter: "agTextColumnFilter" },
        { field: "product_family_code", headerName: "Code famille", filter: true },
        { field: "product_code", headerName: "Produit", filter: true },
        { field: "product_label", headerName: "Label produit", filter: "agTextColumnFilter" },
        { field: "customer_subtype_label", headerName: "Enseigne", filter: "agTextColumnFilter" },
        { field: "customer_subtype_code", headerName: "Code enseigne", filter: true },
        { field: "commercial_name", headerName: "Commercial" },
        {
          field: "unit_price",
          headerName: "PU (€)",
          cellStyle: { textAlign: "right" },
          valueGetter: currencyValueGetter(),
          valueFormatter: currencyValueFormatter(),
        },
        {
          field: "total_sales_quantity",
          headerName: "Qté avant éq.",
          cellStyle: { textAlign: "right" },
          valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        },
      ],
    },
    {
      headerName: "Quantités",
      children: weeks,
    },
  ];

  const gridOptions: GridOptions = {
    defaultColDef: {
      suppressAutoSize: false,
      filter: false,
      flex: 0,
    },
    autoGroupColumnDef: {
      headerName: "Filière",
      minWidth: 80,
    },
    groupMultiAutoColumn: true,
    defaultGroupOrderComparator: function (nodeA, nodeB) {
      const a = nodeA.key ?? "0";
      const b = nodeB.key ?? "0";
      return a < b ? -1 : a > b ? 1 : 0;
    },
    columnDefs: columnDefs,
  };

  return <ServerSideGrid baseUrl={baseUrl} gridOptions={gridOptions} />;
};

export default BudgetForecastResultGrid;
