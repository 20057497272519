import { useState } from "react";
import moment from "moment";

import { Button } from "@zendeskgarden/react-buttons";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Paragraph, Span } from "@zendeskgarden/react-typography";

import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { Forecast } from "../../../interfaces";
import LoadingModal from "../../../components/LoadingModal";

const BudgetForecastActivityDialog = (props: { forecast: Forecast; close: Function }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const api = useApiInstance();

  const handleActivityUpdate = () => {
    const { forecast } = props;
    setLoading(true);
    api
      .put(`activity/${forecast.id}`)
      .then(() => {
        addToast(
          "Historique regénéré",
          `L'historique des ventes pour la prévision #${forecast.id} a été regénérée`,
          "success",
          "top"
        );
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la regénération de l'historique des ventes", "error", "top");
        setLoading(false);
      });
  };

  return (
    <LoadingModal loading={loading} loadingMessage={"Mise à jours en cours..."} onClose={() => props.close(false)}>
      <ModalHeader>Mettre à jour l'historique de la prévision budgétaire</ModalHeader>
      <Body>
        <Paragraph>
          Confirmez-vous la mise à jour de l'historique de la prévision budgétaire : "
          <Span isBold>{props.forecast.label}</Span>" (#{props.forecast.id}) ?
        </Paragraph>
        <Paragraph>
          <Span hue="blue">
            La dernière version de l'historique sera pris en compte, jusqu'au mois
            {[4, 8, 10].includes(props.forecast.period) ? " d'" : " de "}
            {moment(props.forecast.period, "MM").format("MMMM")}, et les ajustements éventuels sélectionnés seront
            appliqués.
          </Span>
        </Paragraph>
        {props.forecast.generated && (
          <Paragraph>
            <Span hue="red">
              Attention, la mise à jour de l'historique entraîne la suppression de la proposition de ventes annuelles !{" "}
            </Span>
            <Span>
              Les saisies éventuelles déjà effectuées sur la proposition de ventes annuelles seront conservées.
            </Span>
          </Paragraph>
        )}
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary onClick={() => handleActivityUpdate()}>
            Mettre à jour l'historique
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};
export default BudgetForecastActivityDialog;
