import { useState } from "react";
import { useParams } from "react-router-dom";
import Moment from "react-moment";

import { Button } from "@zendeskgarden/react-buttons";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Paragraph, Title, Well } from "@zendeskgarden/react-notifications";
import { SM, Span } from "@zendeskgarden/react-typography";

import { ReactComponent as CheckOffIcon } from "@zendeskgarden/svg-icons/src/16/circle-full-stroke.svg";
import { ReactComponent as CheckOnIcon } from "@zendeskgarden/svg-icons/src/16/check-circle-fill.svg";
import { ReactComponent as ForecastIcon } from "@zendeskgarden/svg-icons/src/26/book.svg";
import { ReactComponent as HistoryIcon } from "@zendeskgarden/svg-icons/src/26/workflow.svg";
import { ReactComponent as ParameterIcon } from "@zendeskgarden/svg-icons/src/26/line-chart.svg";
import { ReactComponent as SalesIcon } from "@zendeskgarden/svg-icons/src/26/play.svg";
import { ReactComponent as WeekIcon } from "@zendeskgarden/svg-icons/src/26/dashboard.svg";

import BudgetForecastActivityDialog from "../dialogs/BudgetForecastActivityDialog";
import BudgetForecastProposalDialog from "../dialogs/BudgetForecastProposalDialog";
import BudgetForecastUpdateDialog from "../dialogs/BudgetForecastUpdateDialog";
import ForecastStatusTag from "../../../components/ForecastStatusTag";
import { PaddedMain, StyledCol } from "../../../components/Styled";
import { ApiRefetch } from "../../../hooks/useApi";
import { BudgetForecast } from "../../../interfaces";
import BudgetForecastForecastDialog from "../dialogs/BudgetForecastForecastDialog";

const BudgetForecastParams = (props: { forecast: BudgetForecast; refetch: ApiRefetch<any> }) => {
  const { forecast, refetch } = props;
  const { id } = useParams();

  const [updateDialogVisible, setUpdateDialogVisible] = useState(false);
  const [activityDialogVisible, setActivityDialogVisible] = useState(false);
  const [proposalDialogVisible, setProposalDialogVisible] = useState(false);
  const [forecastDialogVisible, setForecastDialogVisible] = useState(false);

  const onUpdateDialogClose = (success: boolean) => {
    setUpdateDialogVisible(false);
    if (success) {
      refetch();
    }
  };

  const onActivityDialogClose = (success: boolean) => {
    setActivityDialogVisible(false);
    if (success) {
      refetch();
    }
  };

  const onProposalDialogClose = (success: boolean) => {
    setProposalDialogVisible(false);
    if (success) {
      refetch();
    }
  };

  const onForecastDialogClose = (success: boolean) => {
    setForecastDialogVisible(false);
    if (success) {
      refetch();
    }
  };

  return (
    <PaddedMain>
      <Row>
        <Col sm={4}>
          <Well isRecessed>
            <Title>
              <Span>
                <Span.StartIcon>
                  <ForecastIcon />
                </Span.StartIcon>
                Prévision
              </Span>
            </Title>
            <Paragraph>
              Libellé : <Span isBold>{forecast.label}</Span>
            </Paragraph>
            <Paragraph style={{ marginBottom: "20px" }}>
              Année cible : <Span isBold>{forecast.year}</Span>
            </Paragraph>
            <ForecastStatusTag forecast={forecast} />
          </Well>
        </Col>
        <StyledCol sm={4}>
          <Well isRecessed>
            <Title>
              <Span>
                <Span.StartIcon>
                  <HistoryIcon />
                </Span.StartIcon>
                Paramètres de l'historique
              </Span>
            </Title>
            <Paragraph>
              Mois de référence : <Span isBold>{forecast.period}</Span>
            </Paragraph>
            <Paragraph>{forecast.activityMatchingOn ? <CheckOnIcon /> : <CheckOffIcon />} Alignements</Paragraph>
            <Paragraph>{forecast.activityExclusionOn ? <CheckOnIcon /> : <CheckOffIcon />} Exclusions</Paragraph>
            <Paragraph>{forecast.activityOverwriteOn ? <CheckOnIcon /> : <CheckOffIcon />} Surcharges</Paragraph>
          </Well>
          <Well isRecessed style={{ marginTop: 10 }}>
            <Title>
              <Span>
                <Span.StartIcon>
                  <ParameterIcon />
                </Span.StartIcon>
                Paramètres de projection
              </Span>
            </Title>
            <Paragraph>
              Taux d'évolution des méli-mélos : <Span isBold>{forecast.melimeloTrend}</Span>
            </Paragraph>
          </Well>
          <Well isRecessed style={{ marginTop: 10 }}>
            <Button
              isStretched
              isPrimary
              style={{ marginTop: 10 }}
              onClick={() => setUpdateDialogVisible(true)}
              disabled={forecast.status !== "DRAFT"}
            >
              Modifier les paramètres
            </Button>
          </Well>
        </StyledCol>
        <StyledCol sm={4}>
          <Well isRecessed>
            <Title>
              <Span>
                <Span.StartIcon>
                  <HistoryIcon />
                </Span.StartIcon>
                Historique de référence
              </Span>
            </Title>
            <Paragraph>
              Dernière mise à jour :&nbsp;
              <Span isBold>
                <Moment format="D MMM YYYY à HH:mm">{forecast.reset ?? forecast.created}</Moment>
              </Span>
            </Paragraph>
            <Button
              isStretched
              isPrimary
              style={{ marginTop: 10 }}
              onClick={() => setActivityDialogVisible(true)}
              disabled={forecast.status !== "DRAFT"}
            >
              Mettre à jour l'historique
            </Button>
          </Well>
          <Well isRecessed style={{ marginTop: 10 }}>
            <Title>
              <Span>
                <Span.StartIcon>
                  <SalesIcon />
                </Span.StartIcon>
                Proposition de ventes annuelles
              </Span>
            </Title>
            {forecast.generated && (
              <Paragraph>
                Dernière génération :&nbsp;
                <Span isBold>
                  <Moment format="D MMM YYYY à HH:mm">{forecast.generated}</Moment>
                </Span>
              </Paragraph>
            )}
            <Paragraph>
              <Button
                isStretched
                isPrimary
                style={{ marginTop: 10 }}
                onClick={() => setProposalDialogVisible(true)}
                disabled={forecast.status !== "DRAFT"}
              >
                {forecast.generated === null ? "Générer" : "Regénérer"} la proposition #{id}
              </Button>
            </Paragraph>
          </Well>
          <Well isRecessed style={{ marginTop: 10 }}>
            <Title>
              <Span>
                <Span.StartIcon>
                  <WeekIcon />
                </Span.StartIcon>
                Prévision éclatée à la semaine
              </Span>
            </Title>
            <SM>La prévision doit être lancée après avoir complété les propositions de ventes annuelles.</SM>
            {forecast.forecasted && (
              <Paragraph>
                Dernière génération :&nbsp;
                <Span isBold>
                  <Moment format="D MMM YYYY à HH:mm">{forecast.forecasted}</Moment>
                </Span>
              </Paragraph>
            )}
            <Paragraph>
              <Button
                isStretched
                isPrimary
                style={{ marginTop: 10 }}
                onClick={() => setForecastDialogVisible(true)}
                disabled={forecast.status !== "DRAFT" || forecast.generated === null}
              >
                {forecast.forecasted ? "Relancer" : "Lancer"} la prévision #{id}
              </Button>
            </Paragraph>
          </Well>
        </StyledCol>
      </Row>
      {forecast && updateDialogVisible && (
        <BudgetForecastUpdateDialog forecast={forecast} close={onUpdateDialogClose} />
      )}
      {forecast && activityDialogVisible && (
        <BudgetForecastActivityDialog forecast={forecast} close={onActivityDialogClose} />
      )}
      {forecast && proposalDialogVisible && (
        <BudgetForecastProposalDialog forecast={forecast} close={onProposalDialogClose} />
      )}
      {forecast && forecastDialogVisible && (
        <BudgetForecastForecastDialog forecast={forecast} close={onForecastDialogClose} />
      )}
    </PaddedMain>
  );
};

export default BudgetForecastParams;
