import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { Alert } from "@zendeskgarden/react-notifications";

import BudgetForecastResultGrid from "../grids/BudgetForecastResultGrid";
import BudgetForecastInputResultGrid from "../grids/BudgetForecastInputResultGrid";

import { RouteTab, RouteTabPanel, RouteTabs } from "../../../components/RouteTabs";
import { CompactTabList } from "../../../components/Styled";
import { BudgetForecast } from "../../../interfaces";
import { ApiRefetch, useApiInstance } from "../../../hooks/useApi";

const BudgetForecastResultView = (props: { forecast: BudgetForecast; refetch: ApiRefetch<any> }) => {
  const { forecast, refetch } = props;
  const api = useApiInstance();
  const [generated, setGenerated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    api
      .get(`/budget/forecast/${forecast.id}`)
      .then((res) => setGenerated(res.data))
      .catch(() => setGenerated(false))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <></>;
  }

  if (!generated) {
    return (
      <Alert className="m-5" type="warning">
        La prévision n'a pas encore été générée...
      </Alert>
    );
  }

  if (!forecast.generated) {
    return (
      <Alert className="m-5" type="warning">
        La proposition des ventes annuelles n'a pas encore été générée...
      </Alert>
    );
  }

  return (
    <RouteTabs>
      <CompactTabList>
        <RouteTab to="">Prévision</RouteTab>
        <RouteTab to="input">Consommations et surcharges</RouteTab>
      </CompactTabList>
      <RouteTabPanel>
        <Routes>
          <Route index element={<BudgetForecastResultGrid forecast={forecast} />} />
          <Route
            path="input/*"
            element={
              <BudgetForecastInputResultGrid forecast={forecast} onQtyUpdate={() => console.log("quantity update")} />
            }
          />
        </Routes>
      </RouteTabPanel>
    </RouteTabs>
  );
};

export default BudgetForecastResultView;
