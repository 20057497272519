import { useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Paragraph, Span } from "@zendeskgarden/react-typography";

import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { Forecast } from "../../../interfaces";
import LoadingModal from "../../../components/LoadingModal";

const BudgetForecastProposalDialog = (props: { forecast: Forecast; close: Function }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const api = useApiInstance();
  const { forecast } = props;

  const handleProposalGeneration = () => {
    setLoading(true);
    api
      .post(`sales/generate/${forecast.id}`)
      .then(() => {
        addToast(
          "Proposition générée",
          `La proposition des ventes annuelles pour la prévision #${forecast.id} a été générée`,
          "success",
          "top"
        );
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la génération de la proposition des ventes annuelles", "error", "top");
        setLoading(false);
      });
  };

  return (
    <LoadingModal loading={loading} loadingMessage={"Génération en cours..."} onClose={() => props.close(false)}>
      <ModalHeader>
        {forecast.generated === null ? "Générer" : "Regénérer"} la proposition des ventes annuelles
      </ModalHeader>
      <Body>
        <Paragraph>
          Confirmez-vous la {forecast.generated === null ? "génération" : "regénération"} de la proposition des ventes
          annuelles de la prévision budgétaire : "<Span isBold>{props.forecast.label}</Span>" (#
          {props.forecast.id}) ?
        </Paragraph>
        {forecast.generated && (
          <Paragraph>
            <Span hue="blue">
              Les saisies éventuelles déjà effectuées sur la proposition de ventes annuelles seront conservées.
            </Span>
          </Paragraph>
        )}
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary onClick={() => handleProposalGeneration()}>
            Générer la proposition
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};
export default BudgetForecastProposalDialog;
