import { CellValueChangedEvent, ColDef, FilterChangedEvent, GridOptions } from "ag-grid-community";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import { Forecast } from "../../../interfaces";
import {
  currencyFilterParams,
  currencyValueFormatter,
  currencyValueGetter,
  currencyValueParser,
  dateValueFormatter,
  numberValueFormatter,
} from "../../../utils/helpers";

const BLUE = "#1f73b7";
const GREEN = "#365e2b";
const GREY = "#565656";

const BudgetForecastProposalGrid = ({
  forecast,
  baseUrl,
  onCellValueUpdate,
  onGridFilterUpdate,
}: {
  forecast: Forecast;
  baseUrl: string;
  onCellValueUpdate: (params: CellValueChangedEvent) => void;
  onGridFilterUpdate: (model: { [p: string]: any } | null) => void;
}) => {
  const editable = forecast && forecast.status === "DRAFT";

  const quantityColDef: ColDef = {
    filter: "agNumberColumnFilter",
    type: "numericColumn",
    valueFormatter: numberValueFormatter({
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      suffix: "",
    }),
  };

  // for column move from inside column sidebar, upgrade to ag-grid 26.1.0 -- see
  // https://stackoverflow.com/questions/65413802/is-there-way-moving-columns-in-the-column-section-of-the-tool-panel-of-ag-grid
  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "product_family_label", headerName: "Famille", filter: "agTextColumnFilter" },
      { field: "product_family_code", headerName: "Code famille", filter: true, hide: true },
      { field: "product_code", headerName: "Produit" },
      { field: "product_label", headerName: "Label produit", filter: "agTextColumnFilter" },
      { field: "customer_subtype_label", headerName: "Enseigne", filter: "agTextColumnFilter" },
      { field: "customer_subtype_code", headerName: "Code enseigne", filter: true, hide: true },
      { field: "commercial_name", headerName: "Commercial" },
      {
        field: "last_year_raw_sales_quantity_kg",
        headerName: "Qté brute A-1 (kg)",
        cellStyle: { "font-weight": "400", color: GREY },
        ...quantityColDef,
      },
      {
        field: "current_year_raw_sales_quantity_kg",
        headerName: "Qté brute A (kg)",
        cellStyle: { "font-weight": "400", color: GREY },
        ...quantityColDef,
      },
      {
        field: "next_year_sales_quantity_kg",
        headerName: "Qté A+1 (kg)",
        ...quantityColDef,
      },
      {
        field: "proposed_sales_quantity_kg",
        headerName: "Qté proposée (kg)",
        ...quantityColDef,
        cellStyle: { color: BLUE },
        cellRenderer: "agAnimateShowChangeCellRenderer",
        editable,
        cellEditor: "numberCellEditor",
        cellEditorParams: { step: 1 },
      },
      {
        field: "last_year_unit_price",
        headerName: "PU A-1",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        filterParams: currencyFilterParams,
        valueGetter: currencyValueGetter(),
        valueFormatter: currencyValueFormatter(),
      },
      {
        field: "current_year_unit_price",
        headerName: "PU A",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        filterParams: currencyFilterParams,
        valueGetter: currencyValueGetter(),
        valueFormatter: currencyValueFormatter(),
      },
      {
        field: "expected_unit_price",
        headerName: "PU validé",
        type: "numericColumn",
        cellStyle: { color: BLUE },
        cellRenderer: "agAnimateShowChangeCellRenderer",
        editable,
        filter: "agNumberColumnFilter",
        filterParams: currencyFilterParams,
        valueGetter: currencyValueGetter(),
        valueFormatter: currencyValueFormatter(),
        valueParser: currencyValueParser(),
        cellEditor: "numberCellEditor",
        cellEditorParams: { step: 0.01 },
      },
      {
        field: "sales_start",
        headerName: "Date début",
        cellStyle: { color: GREEN },
        filter: "agDateColumnFilter",
        editable: true,
        cellEditor: "dateCellEditor",
        cellEditorParams: { nullable: true },
        valueFormatter: dateValueFormatter(),
      },
      {
        field: "sales_end",
        headerName: "Date fin",
        cellStyle: { color: GREEN },
        filter: "agDateColumnFilter",
        editable: true,
        cellEditor: "dateCellEditor",
        cellEditorParams: { nullable: true },
        valueFormatter: dateValueFormatter(),
      },
      {
        field: "updated",
        headerName: "Mise à jour",
        filter: "agDateColumnFilter",
        valueFormatter: dateValueFormatter("DD/MM/YYYY HH:mm"),
        hide: true,
      },
    ],
    onCellValueChanged: onCellValueUpdate,
    onFilterChanged: (params: FilterChangedEvent) => onGridFilterUpdate(params.api?.getFilterModel()),
    enableCellChangeFlash: true,
    undoRedoCellEditing: true,
  };

  return <ServerSideGrid baseUrl={baseUrl} key={`${baseUrl}_proposal`} gridOptions={gridOptions} />;
};

export default BudgetForecastProposalGrid;
