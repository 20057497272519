import { useRef, useState } from "react";
import FlexView from "react-flexview";

import { CellValueChangedEvent, GridApi } from "ag-grid-community";

import { Alert } from "@zendeskgarden/react-notifications";
import { LG } from "@zendeskgarden/react-typography";

import BudgetForecastProposalGrid from "../grids/BudgetForecastProposalGrid";
import BudgetForecastValidationGrid from "../grids/BudgetForecastValidationGrid";
import BudgetForecastInputConsumptionGrid from "../grids/BudgetForecastInputConsumptionGrid";

import { ApiRefetch, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { BudgetForecast } from "../../../interfaces";

const BudgetForecastProposal = (props: { forecast: BudgetForecast; refetch: ApiRefetch<any>; step: string }) => {
  const { forecast, refetch, step } = props;
  const { addToast } = useToast();
  const preveolApi = useApiInstance();
  const consumptionGridApi = useRef<GridApi>();
  const [inputFocusLevel, setInputFocusLevel] = useState(0);

  const generated = forecast.generated !== null;
  const baseUrl = `/sales/${forecast.id}`;

  const significantFields = ["product_family_code", "product_family_label", "product_code", "product_label"];
  const onGridFilterUpdate = (gridFilterModel: { [key: string]: any } | null) => {
    // list of family codes associated to filtered relevant fields
    if (gridFilterModel && significantFields.some((p) => gridFilterModel.hasOwnProperty(p))) {
      if (gridFilterModel.hasOwnProperty("product_family_code")) {
        updateConsumptionFilter(gridFilterModel, gridFilterModel.product_family_code.values);
      } else {
        preveolApi
          .post(`${baseUrl}/values`, {
            field: `product_family_code`,
            filterModel: gridFilterModel,
          })
          .then((req) => {
            updateConsumptionFilter(gridFilterModel, req.data);
          })
          .catch(() => {
            updateConsumptionFilter(gridFilterModel, null);
          });
      }
    } else {
      updateConsumptionFilter(gridFilterModel, null);
    }
  };
  const updateConsumptionFilter = (model: { [key: string]: any } | null, families: string[] | null) => {
    if (model && significantFields.some((p) => model.hasOwnProperty(p))) {
      let filterModel: { [key: string]: any } = {};
      filterModel["component_family_code"] = { filterType: "set", values: families };
      const level = Math.min(4, families ? families.length : 2);
      setInputFilter(filterModel, level);
    } else {
      resetInputFilter();
    }
  };

  const setInputFilter = (model: { [key: string]: any }, level: number) => {
    consumptionGridApi.current?.setFilterModel(model);
    consumptionGridApi.current?.refreshServerSideStore({ purge: true });
    setInputFocusLevel(level);
  };

  const resetInputFilter = () => {
    consumptionGridApi.current?.setFilterModel(null);
    setInputFocusLevel(0);
  };

  function updateLifecycle(params: CellValueChangedEvent, columnId: string, newValue: string) {
    preveolApi
      .put(`lifecycle`, {
        productFamilyCode: params.data.product_family_code,
        level: 3,
        lifecycle: {
          productCode: params.data.product_code,
          customerSubtypeCode: params.data.customer_subtype_code,
          salesStart: columnId === "sales_start" ? newValue : params.data.sales_start,
          salesEnd: columnId === "sales_end" ? newValue : params.data.sales_end,
          year: forecast.year,
        },
      })
      .then((req) => {
        addToast("Mise à jour", `Mise à jour effectuée pour ${params.data.product_code}.`, "success");
      })
      .catch(() => {
        addToast("Erreur", `La valeur ${newValue} est erronée. Veuillez corriger.`, "error");
      });
  }

  const cellValueUpdate = (params: CellValueChangedEvent) => {
    const columnId = params.column.getColId();
    let oldValue = params.oldValue;
    let newValue = params.newValue;
    if (columnId === "expected_unit_price") {
      // for validation page, newValue is an object
      if (
        newValue.hasOwnProperty("expected_unit_price") &&
        !newValue.expected_unit_price &&
        !oldValue.expected_unit_price
      ) {
        return;
      }
      if (oldValue) oldValue = Math.round(parseFloat(oldValue) * 100.0);
      if (newValue) newValue = Math.round(parseFloat(newValue) * 100.0);
    }
    if (columnId === "sales_start" || columnId === "sales_end") {
      updateLifecycle(params, columnId, newValue);
    } else {
      preveolApi
        .put(`sales/entry/simple/${forecast.id}`, {
          column: columnId,
          oldValue: oldValue,
          newValue: newValue,
          product: params.data.product_code,
          customer: params.data.customer_subtype_code,
          commercial: params.data.commercial_name,
        })
        .then((req) => {
          addToast("Valeur mise à jour", `Mise à jour effectuée pour ${params.data.product_code}.`, "success");
          if (columnId !== "expected_unit_price") {
            consumptionGridApi.current?.refreshServerSideStore({ purge: true });
          }
        })
        .catch((err: TypeError) => {
          let message = `La valeur ${params.newValue} est erronée. Veuillez corriger.`;
          if (forecast.status !== "DRAFT") {
            message = "Modification impossible sur les budgets validés.";
          }
          addToast("Erreur", message, "error", "top");
          // reset old value in grid
          params.data[columnId] = params.oldValue;
        });
    }
  };

  if (!generated) {
    return (
      <Alert className="m-5" type="warning">
        La proposition des ventes annuelles n'a pas encore été générée...
      </Alert>
    );
  }

  return (
    <FlexView column height="100%">
      <LG style={{ margin: 5 }} hidden={!inputFocusLevel}>
        Consommation des apports
      </LG>
      <FlexView grow={inputFocusLevel} shrink={1}>
        <BudgetForecastInputConsumptionGrid
          forecast={forecast}
          onGridReady={({ api }) => (consumptionGridApi.current = api)}
        />
      </FlexView>
      <LG style={{ margin: 5 }}>Proposition #{forecast.id}</LG>
      <FlexView grow={(inputFocusLevel + 2) * 3} shrink={1}>
        {step === "proposal" && (
          <BudgetForecastProposalGrid
            forecast={forecast}
            baseUrl={baseUrl}
            onCellValueUpdate={(params) => cellValueUpdate(params)}
            onGridFilterUpdate={(model) => onGridFilterUpdate(model)}
          />
        )}
        {step === "validation" && (
          <BudgetForecastValidationGrid
            forecast={forecast}
            baseUrl={baseUrl}
            onQtyUpdate={() => consumptionGridApi.current?.refreshServerSideStore({ purge: true })}
            onCellValueUpdate={(params) => cellValueUpdate(params)}
            onGridFilterUpdate={(model) => onGridFilterUpdate(model)}
          />
        )}
      </FlexView>
    </FlexView>
  );
};

export default BudgetForecastProposal;
