import { useState } from "react";
import { Link, Route, useParams, Routes } from "react-router-dom";

import { Body as ChromeBody, HeaderItemWrapper, Content, Main } from "@zendeskgarden/react-chrome";
import { Breadcrumb } from "@zendeskgarden/react-breadcrumbs";
import { MD, Span } from "@zendeskgarden/react-typography";
import { Button } from "@zendeskgarden/react-buttons";

import ActivityView from "../activity/ActivityView";
import BudgetForecastOverwrite from "./components/BudgetForecastOverwrite";
import BudgetForecastParams from "./components/BudgetForecastParams";
import BudgetForecastProposal from "./components/BudgetForecastProposal";
import BudgetForecastResultView from "./components/BudgetForecastResultView";
import BudgetForecastValidateDialog from "./dialogs/BudgetForecastValidateDialog";

import ForecastInputView from "../common/ForecastInputView";
import Header from "../../components/Header";
import { RouteTabs, RouteTab, RouteTabPanel } from "../../components/RouteTabs";
import { CompactTabList } from "../../components/Styled";
import { useApiGet } from "../../hooks/useApi";

const BudgetForecastView = () => {
  const { id } = useParams();

  const [validateDialogVisible, setValidateDialogVisible] = useState(false);
  const [{ data: forecast }, refetch] = useApiGet(`/forecast/${id}`, {});

  return (
    <ChromeBody>
      <Header helmetTitle={forecast && `Budget #${forecast.id}`}>
        <HeaderItemWrapper maxX>
          <Breadcrumb>
            <Link to="/budget">Prévisions budgétaires</Link>
            <Span>{forecast && `Budget #${forecast.id}`}</Span>
          </Breadcrumb>
        </HeaderItemWrapper>
        <HeaderItemWrapper>
          {forecast && forecast.type === "BUDGET" && (
            <Button
              isPrimary
              size="small"
              onClick={() => setValidateDialogVisible(true)}
              disabled={forecast.status !== "DRAFT" || !forecast.generated || !forecast.forecasted}
            >
              Valider le budget
            </Button>
          )}
        </HeaderItemWrapper>
      </Header>
      <Content>
        <Main>
          {forecast && forecast.type === "WEEKLY" && (
            <>
              <MD style={{ color: "red", marginLeft: 18, marginTop: 20 }} isBold>
                La prévision #{forecast.id} n'est pas une prévision budget !
              </MD>
              <MD style={{ color: "red", marginLeft: 18 }}>
                Retrouvez-la ici : <Link to={`/forecast/${forecast.id}`}>{forecast.label}</Link>
              </MD>
            </>
          )}
          {forecast && forecast.type === "BUDGET" && (
            <RouteTabs>
              <CompactTabList>
                <RouteTab to="">Vue générale</RouteTab>
                <RouteTab to="overwrite">Surcharge scénario</RouteTab>
                <RouteTab to="activity">Historique</RouteTab>
                <RouteTab to="input">Apports</RouteTab>
                <RouteTab to="proposal" disabled={!forecast.generated}>
                  Proposition
                </RouteTab>
                <RouteTab to="validation" disabled={!forecast.generated}>
                  Validation
                </RouteTab>
                <RouteTab to="result" disabled={!forecast.generated || !forecast.forecasted}>
                  Prévision
                </RouteTab>
              </CompactTabList>
              <RouteTabPanel>
                <Routes>
                  <Route index element={<BudgetForecastParams forecast={forecast} refetch={refetch} />} />
                  <Route
                    path="overwrite/*"
                    element={<BudgetForecastOverwrite forecast={forecast} refetch={refetch} />}
                  />
                  <Route path="activity/*" element={<ActivityView forecast={forecast} refetch={refetch} />} />
                  <Route path="input/*" element={<ForecastInputView forecast={forecast} refetch={refetch} />} />
                  <Route
                    path="proposal/*"
                    element={<BudgetForecastProposal forecast={forecast} refetch={refetch} step={"proposal"} />}
                  />
                  <Route
                    path="validation/*"
                    element={<BudgetForecastProposal forecast={forecast} refetch={refetch} step={"validation"} />}
                  />
                  <Route path="result/*" element={<BudgetForecastResultView forecast={forecast} refetch={refetch} />} />
                </Routes>
              </RouteTabPanel>
            </RouteTabs>
          )}
        </Main>
      </Content>
      {validateDialogVisible && forecast && forecast.forecasted && (
        <BudgetForecastValidateDialog forecast={forecast} close={() => setValidateDialogVisible(false)} />
      )}
    </ChromeBody>
  );
};

export default BudgetForecastView;
