import { useEffect, useState } from "react";

import { IToolPanelParams, ModelUpdatedEvent } from "ag-grid-community";

import { Button, IconButton } from "@zendeskgarden/react-buttons";
import { FooterItem } from "@zendeskgarden/react-chrome";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Body, Caption, Cell, Head, HeaderCell, Table } from "@zendeskgarden/react-tables";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { MD } from "@zendeskgarden/react-typography";

import { ReactComponent as DeleteIcon } from "@zendeskgarden/svg-icons/src/12/trash-fill.svg";

import BudgetCombinationExceptionDialog from "../../dialogs/BudgetCombinationExceptionDialog";
import { AGHeaderRow, AGRow, CompactFooter } from "../../../../components/Styled";
import { useApiGet, useApiInstance } from "../../../../hooks/useApi";
import useToast from "../../../../hooks/useToast";
import { BudgetCombination, BudgetForecast } from "../../../../interfaces";

const BudgetCombinationExceptionPanel = (
  props: IToolPanelParams & {
    forecast: BudgetForecast;
    refreshGrid: Function;
  }
) => {
  const { forecast, api } = props;

  const { addToast } = useToast();
  const preveolApi = useApiInstance();

  const [combinations, setCombinations] = useState<BudgetCombination[] | []>([]);
  const [combinationExceptionDialogVisible, setCombinationExceptionDialogVisible] = useState(false);

  const [{ data }, refetch] = useApiGet(`/sales/${forecast.id}/combination`);

  const onCombinationExceptionDialogClose = (success: boolean) => {
    setCombinationExceptionDialogVisible(false);
    if (success) {
      refetch();
      props.refreshGrid();
    }
  };

  const update = (evt: ModelUpdatedEvent) => {
    // update existing combinations from grid data
    let existingCombinations: BudgetCombination[] = [];
    evt.api.forEachNode(function (node) {
      const data = node.data;
      if (data) {
        const gridCombination: BudgetCombination = {
          product_code: data.product_code,
          customer_subtype_code: data.customer_subtype_code,
          commercial_name: data.commercial_name,
        };
        if (!existingCombinations.find((combination) => combination === gridCombination)) {
          existingCombinations.push(gridCombination);
        }
      }
    });
    setCombinations(existingCombinations);
  };

  const deleteCombination = (item: { [key: string]: string }) => {
    const combination = `${item.product_code}/${item.customer_subtype_code}/${item.commercial_name}`;
    preveolApi
      .delete(`/sales/${forecast.id}/combination/${combination}`)
      .then(() => {
        addToast(
          `Suppression d'ouverture`,
          `L'ouverture du produit #${item.product_code} a été supprimée`,
          "success",
          "top"
        );
        refetch();
        props.refreshGrid();
      })
      .catch(() => {
        addToast("Erreur", `Erreur à la suppression de l'ouverture`, "error", "top");
      });
  };

  useEffect(() => {
    api.addEventListener("modelUpdated", update);
    return () => api.removeEventListener("modelUpdated", update);
  }, []);

  return (
    <>
      <Table size="small" style={{ fontSize: "small", minWidth: 450 }}>
        <Caption>
          <MD style={{ margin: 15 }} isBold>
            Ouverture de combinaisons
          </MD>
        </Caption>
        {data && (
          <Head>
            <AGHeaderRow>
              <HeaderCell>Produit</HeaderCell>
              <HeaderCell>Enseigne</HeaderCell>
              <HeaderCell>Commercial</HeaderCell>
            </AGHeaderRow>
          </Head>
        )}
        <Body>
          {data &&
            data.length > 0 &&
            data.map((item: { [key: string]: string }) => (
              <AGRow key={`${item.product_code}-${item.customer_subtype_code}-${item.commercial_name}`}>
                <Cell>{item.product_code}</Cell>
                <Cell>{item.customer_subtype_code}</Cell>
                <Cell>{item.commercial_name}</Cell>
                <Tooltip placement="top" type="dark" size="small" content="Supprimer l'ouverture">
                  <IconButton isDanger size="small" onClick={() => deleteCombination(item)} style={{ marginLeft: 2 }}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </AGRow>
            ))}
        </Body>
      </Table>
      {data && data.length === 0 && (
        <Row alignItems="center" style={{ minHeight: "6em" }}>
          <Col>
            <MD style={{ textAlign: "center" }}>Aucune ouverture</MD>
          </Col>
        </Row>
      )}
      <CompactFooter>
        <FooterItem>
          <Button size="small" isPrimary onClick={() => setCombinationExceptionDialogVisible(true)}>
            Ouvrir une combinaison
          </Button>
        </FooterItem>
      </CompactFooter>
      {forecast && combinationExceptionDialogVisible && (
        <BudgetCombinationExceptionDialog
          close={onCombinationExceptionDialogClose}
          forecast={forecast}
          gridCombinations={combinations}
        />
      )}
    </>
  );
};

export default BudgetCombinationExceptionPanel;
