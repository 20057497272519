import { useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Toggle, Message } from "@zendeskgarden/react-forms";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";

import { Form } from "../../../components/Form";
import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { Forecast } from "../../../interfaces";
import LoadingModal from "../../../components/LoadingModal";

const BudgetForecastCreateDialog = (props: { close: Function }) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const api = useApiInstance();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const addBudgetForecast = (data: any) => {
    setLoading(true);
    api
      .post(`forecast`, { ...data, type: "BUDGET" })
      .then((response) => {
        const forecast: Forecast = response.data;
        addToast("Prévision créée", `La prévision #${forecast.id} a été créée`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la création de la prévision", "error", "top");
        setLoading(false);
      });
  };

  return (
    <LoadingModal loading={loading} loadingMessage="Création en cours..." onClose={() => props.close(false)}>
      <ModalHeader>Créer une nouvelle prévision budgétaire</ModalHeader>
      <Body>
        <Form id="dialog_form" onSubmit={handleSubmit(addBudgetForecast)}>
          <Field>
            <Label>Libellé</Label>
            <Input {...register("label", { required: true })} validation={errors.label ? "error" : undefined} />
            {errors.label && (
              <Message className="mt-2" validation="error">
                Vous devez renseigner un libellé
              </Message>
            )}
          </Field>
          <Field>
            <Label>Année cible</Label>
            <Input
              type="number"
              {...register("year", { value: new Date().getFullYear(), required: true })}
              validation={errors.year ? "error" : undefined}
            />
            {errors.year && (
              <Message className="mt-2" validation="error">
                Vous devez renseigner une année
              </Message>
            )}
          </Field>
          <Field>
            <Label>Mois de référence</Label>
            <Input
              type="number"
              {...register("period", { value: 1, required: true })}
              validation={errors.period ? "error" : undefined}
            />
            {errors.period && (
              <Message className="mt-2" validation="error">
                Vous devez renseigner un mois de référence
              </Message>
            )}
          </Field>
          <Field>
            <Toggle {...register("activityMatchingOn", { value: true })}>
              <Label>Activer les alignements</Label>
            </Toggle>
          </Field>
          <Field>
            <Toggle {...register("activityExclusionOn", { value: true })}>
              <Label>Activer les exclusions</Label>
            </Toggle>
          </Field>
          <Field>
            <Toggle {...register("activityOverwriteOn", { value: true })}>
              <Label>Activer les surcharges</Label>
            </Toggle>
          </Field>
          <Field>
            <Label>Taux d'évolution des méli-mélos</Label>
            <Input type="number" step="any" {...register("melimeloTrend", { value: 1, required: true })} />
          </Field>
        </Form>
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary type="submit" form="dialog_form">
            Créer la prévision
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};

export default BudgetForecastCreateDialog;
