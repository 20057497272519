import { useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input } from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";

import { Datepicker, Form } from "../../../components/Form";
import useToast from "../../../hooks/useToast";
import { BudgetForecast } from "../../../interfaces";
import LoadingModal from "../../../components/LoadingModal";
import { useApiInstance } from "../../../hooks/useApi";
import moment from "moment/moment";

const GROUPED_COLUMNS = ["product_family_label", "product_code", "customer_subtype_label"];
const fieldName = ["la famille", "le produit ", "l'enseigne"];

const BudgetForecastProposalOverwriteDialog = (props: {
  forecast: BudgetForecast;
  column: string;
  data: { [key: string]: any };
  onQtyUpdate: () => void;
  close: (success: boolean) => void;
}) => {
  const api = useApiInstance();
  const { addToast } = useToast();
  const { forecast, close, column, data, onQtyUpdate } = props;
  const [loading, setLoading] = useState(false);

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getValue = (field: string) => {
    let value;
    if (data[field]) {
      value = (data[field][field] / 100.0).toFixed(2);
    } else {
      value = undefined;
    }
    return value;
  };

  const watchProposedSales = watch("proposed_sales_quantity_kg", data.proposed_sales_quantity_kg);
  const watchValidatedSales = watch("validated_sales_quantity_kg", data.validated_sales_quantity_kg);
  const watchExpectedUnitPrice = watch(
    "expected_unit_price",
    data.expected_unit_price.expected_unit_price ? data.expected_unit_price.expected_unit_price / 100.0 : null
  );
  const watchSalesStart = watch("sales_start", data.sales_start ? new Date(data.sales_start) : null);
  const watchSalesEnd = watch("sales_end", data.sales_end ? new Date(data.sales_end) : null);

  const handleFormSubmit = (formData: any) => {
    setLoading(true);
    let newValue: any;
    let oldValue: any;
    const productFamilyCode = data.product_family_code;
    const productCode = data.level != null && data.level < 1 ? null : data.product_code;
    const customerSubtypeCode = data.level != null && data.level < 2 ? null : data.customer_subtype_code;

    if (column === "sales_start" || column === "sales_end") {
      newValue = formData[column] && formData[column] !== "NaN" ? moment(formData[column]).format("YYYY-MM-DD") : null;
      api
        .put(`lifecycle/grouped`, {
          level: data.level,
          productFamilyCode: productFamilyCode,
          lifecycle: {
            productCode: productCode,
            customerSubtypeCode: customerSubtypeCode,
            salesStart: column === "sales_start" ? newValue : data.sales_start,
            salesEnd: column === "sales_end" ? newValue : data.sales_end,
            year: forecast.year,
          },
        })
        .then((req) => {
          close(true);
          const message = productCode ? `Le produit ${productCode}` : `la famille ${productFamilyCode}`;
          addToast("Mise à jour", `Mise à jour effectuée pour ${message}.`, "success");
        })
        .catch(() => {
          addToast("Erreur", `La valeur ${newValue} est erronée. Veuillez corriger.`, "error");
          setLoading(false);
        });
    } else {
      if (column === "expected_unit_price") {
        newValue =
          formData[column] && formData[column] !== "NaN" ? Math.round(parseFloat(formData[column]) * 100.0) : null;
        oldValue = data[column].expected_unit_price ? Math.round(data[column].expected_unit_price) : null;
      } else {
        newValue = formData[column] && formData[column] !== "NaN" ? Math.round(formData[column]) : null;
        oldValue = data[column] ? Math.round(data[column]) : null;
      }
      const payload = {
        column: column,
        level: data.level,
        productFamilyCode: productFamilyCode,
        productCode: productCode,
        customerSubtypeCode: customerSubtypeCode,
        newValue: newValue,
        oldValue: oldValue,
      };
      api
        .put(`/sales/entry/grouped/${forecast.id}`, payload)
        .then(() => {
          close(true);
          addToast(
            "Valeur mise à jour",
            `Mise à jour effectuée pour ${fieldName[data.level]} ${data[GROUPED_COLUMNS[data.level]]}.`,
            "success"
          );
          if (column !== "expected_unit_price") {
            onQtyUpdate();
          }
        })
        .catch(() => {
          addToast("Erreur", `La valeur ${newValue} est erronée. Veuillez corriger.`, "error", "top");
          setLoading(false);
        });
    }
  };

  return (
    <LoadingModal
      loading={loading}
      loadingMessage="Enregistrement en cours..."
      isLarge
      onClose={() => props.close(false)}
    >
      <ModalHeader>{`Saisie avec éclatement au niveau ${data[GROUPED_COLUMNS[data.level]]}`}</ModalHeader>
      <Body>
        <Form id="dialog_form" onSubmit={handleSubmit(handleFormSubmit)}>
          {column === "validated_sales_quantity_kg" && (
            <Row>
              <Col>
                <Field>
                  <Label>Quantité calculée (kg)</Label>
                  <Input
                    type="number"
                    value={parseFloat(data.next_year_sales_quantity_kg).toFixed(0)}
                    readOnly={true}
                    step="0.01"
                  />
                </Field>
              </Col>
              <Col>
                <Field>
                  <Label>Quantité proposée (kg)</Label>
                  <Input
                    type="number"
                    step="0.01"
                    autoFocus={true}
                    {...register("proposed_sales_quantity_kg", {
                      value: parseFloat(watchProposedSales).toFixed(0),
                      required: false,
                    })}
                  />
                </Field>
              </Col>
              <Col>
                <Field>
                  <Label>Quantité validée (kg)</Label>
                  <Input
                    type="number"
                    step="0.01"
                    autoFocus={true}
                    {...register("validated_sales_quantity_kg", {
                      value: parseFloat(watchValidatedSales).toFixed(0),
                      required: false,
                    })}
                  />
                </Field>
              </Col>
            </Row>
          )}
          {column === "expected_unit_price" && (
            <Row>
              <Col>
                <Field>
                  <Label>Prix Unitaire A-1 (€/kg)</Label>
                  <Input type="number" value={getValue("last_year_unit_price")} readOnly={true} step="0.01" />
                </Field>
              </Col>
              <Col>
                <Field>
                  <Label>Prix Unitaire A (€/kg)</Label>
                  <Input type="number" value={getValue("current_year_unit_price")} readOnly={true} step="0.01" />
                </Field>
              </Col>
              <Col>
                <Field>
                  <Label>Prix Unitaire attendu (€/kg)</Label>
                  <Input
                    type="number"
                    step="0.01"
                    autoFocus={true}
                    {...register("expected_unit_price", {
                      value: parseFloat(watchExpectedUnitPrice).toFixed(2),
                      required: false,
                    })}
                  />
                </Field>
              </Col>
            </Row>
          )}
          {(column === "sales_start" || column === "sales_end") && (
            <Row>
              <Col>
                <Field>
                  <Label>Date de début de commercialisation</Label>
                  <Datepicker
                    value={watchSalesStart}
                    onChange={(d) => setValue("sales_start", d)}
                    minValue={new Date(forecast.year, 0, 1)}
                    maxValue={watchSalesEnd}
                    placement={"top"}
                  >
                    <Input
                      {...register("sales_start", { required: false })}
                      validation={errors.sales_start ? "error" : undefined}
                      disabled={column !== "sales_start"}
                    />
                  </Datepicker>
                </Field>
              </Col>
              <Col>
                <Field>
                  <Label>Date de fin de commercialisation</Label>
                  <Datepicker
                    value={watchSalesEnd}
                    onChange={(d) => setValue("sales_end", d)}
                    minValue={watchSalesStart}
                    maxValue={new Date(forecast.year, 11, 31)}
                    placement={"top"}
                  >
                    <Input
                      {...register("sales_end", { required: false })}
                      validation={errors.sales_end ? "error" : undefined}
                      disabled={column !== "sales_end"}
                    />
                  </Datepicker>
                </Field>
              </Col>
            </Row>
          )}
        </Form>
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary type="submit" form="dialog_form" disabled={loading}>
            {loading ? "Enregistrement en cours..." : "Enregistrer"}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};

export default BudgetForecastProposalOverwriteDialog;
